@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

@media screen and (min-width: 1200px) {
  /* Home page */
  .Welcome-text{
    font-size: 5em;
  }
  .App-logo {
    max-width: 30vw;
  }
  .About-text {
    font-size: 3em;
  }
  .Contact-text {
    font-size: 3em;
  }
  .Trial-practices-ad-text {
    font-size: 3em;
  }
  .Trial-practices-ad {
    max-width: 50vw;
  }

  /* About page */
  .About-page ul {
    font-size: 2em;
  }
  .About-page h1 {
    font-size: 4em;
  }
  .About-page img {
    max-width: 40vw;
  }
  .About-page p {
    font-size: 2em;
    padding-left: 5vmax;
    padding-right: 5vmax;
  }
}

@media screen and (max-width: 1199px) {
  /* Home page */
  .Welcome-text{
    font-size: 8vmin;
  }
  .App-logo {
    height: 60vmin;
  }
  .About-text {
    font-size: 5vmin;
  }
  .Contact-text {
    font-size: 5vmin;
  }
  .Trial-practices-ad-text {
    font-size: 5vmin;
  }
  .Trial-practices-ad {
    max-width: 90vw;
  }

  /* About page */
  .About-page ul {
    font-size: 3vmax;
  }
  .About-page h1 {
    font-size: 6vmax;
  }
  .About-page img {
    max-width: 90vw;
  }
  .About-page p {
    font-size: 3vmax;
    padding-left: 6vw;
    padding-right: 6vw;
  }
}

/* Home page */
.App-logo
{
  margin-top: 10vmin;
}

.Info-text-color
{
  color: #00ADEF;
}

.Heading-text-color
{
  color: #00ADEF;
}

.Welcome-text
{
  font-family: "ZTFloogn";
  margin: auto;
  padding-top: 10vmin;
  padding-left: 2vmin;
  padding-right: 2vmin;
}

.About-text
{
  font-family: "AnelizaSemiBold";
  margin: auto;
  padding-top: 10vmin;
  padding-left: 5vmin;
  padding-right: 5vmin;
}

.Contact-text
{
  font-family: "AnelizaSemiBold";
  margin: auto;
  margin-top: 5vmax;
  padding-top: 10vmin;
  padding-left: 5vmin;
  padding-right: 5vmin;
  padding-bottom: 5vmax;
}

.Bold-text
{
  font-family: "AnelizaSemiBold";
  margin: auto;
  padding-top: 2vmin;
  padding-left: 5vmin;
  padding-right: 5vmin;
  padding-bottom: 5vmax;
  font-family: "AnelizaBlack";
  font-size: 6vmin;
}

.Trial-practices-ad-text {
  font-family: "AnelizaSemiBold";
  margin: auto;
  padding-top: 10vmin;
  padding-left: 5vmin;
  padding-right: 5vmin;
  padding-bottom: 5vmax;
}

.Trial-practices-ad {
  padding-bottom: 4vmax;
}

/* Navbar styling */
.Nav
{
  color: #DF3E97;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 2vmax;
  justify-content: space-between;
}

.Nav a {
  color: inherit;
  text-decoration: none;
}

.Nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;

}

.Nav li {
  padding: 1vmin;
}

.Nav li.active {
  background-color: #555;
}

.Nav li:hover {
  color: #ebebeb;
}

.Site-title
{
  font-size: 6vmin;
  white-space: nowrap;
  font-family: "ZTFloogn";
}

.Page-title
{
  font-size: 4vmin;
  font-family: "AnelizaSemiBold";
}

/* About page */
.About-page ul {
  list-style: none;
  display: inline;
  padding: 1vmin;
  font-family: "AnelizaLight";
}

.About-page li:hover {
  color: #ebebeb;
}

.About-page li {
  padding: 1vmin;
}

.Anchor-link {
  color: inherit;
  text-decoration: inherit;
}

.About-page h1 {
  font-family: "ZTFloogn";
}

.About-page img {
  padding-top: 5vmax;
  padding-bottom: 4vmax;

}

.About-page p {
  font-family: "AnelizaLight";
  text-align: left;
 padding-bottom: 6vmax;
  line-height: 1.2;
}

/* Gallery page */
.Slideshow {
  margin: auto;
  max-width: 90vw;
}